<template>
    <div class="CkManage">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
            </div>
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" @submit.native.prevent>
                    <el-form-item label="标签">
                        <!-- <el-input v-focus v-model="auxCode" placeholder="请输入标签查询"></el-input> -->
                        <el-input ref="auxCodeRef"  v-model="auxCode" @keydown.enter.native="courierNumEnterDown"
                            @keyup.enter.native="courierNumEnterUp" placeholder="请输入标签查询" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="username">
                        <el-input  v-model="username" placeholder="请输入标签查询"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="ckSubmit">
                    <el-button  class="btn" @click="handleCk" icon="el-icon-truck">出库</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="auxiliaryInfoList" border style="width: 100%" v-loading="loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="className" label="工具分类" align="center"> </el-table-column>
                <el-table-column prop="auxName" label="工具名称" align="center">
                </el-table-column>
                <el-table-column prop="auxType" label="工具规格型号" align="center">
                </el-table-column>
                <el-table-column prop="auxNum" label="工器具编号" align="center">
                </el-table-column>
                <el-table-column prop="location" label="存放位置" align="center">
                </el-table-column>
                <el-table-column prop="status_text" label="状态" align="center">
                </el-table-column>
                <el-table-column prop="auxPzbz" label="配置标准" align="center">
                </el-table-column>
                <el-table-column prop="auxXypz" label="现有配置" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="删除" class="delColor"
                            @click="handleDel(scope.row.auxCode)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog title="出库信息完善" :visible.sync="dialogFormVisible" width="700px" :before-close="closeInfo">
            <el-form :model="auxilCkInfo" :rules="rules" ref="auxilCkInfo">
                <el-form-item label="出库经办人" :label-width="formLabelWidth" prop="ckJbr">
                    <el-input v-model="auxilCkInfo.ckJbr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库日期" :label-width="formLabelWidth" prop="ckDate">
                    <el-date-picker v-model="auxilCkInfo.ckDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="出库时间" :label-width="formLabelWidth" prop="ckTime">
                    <el-time-picker v-model="auxilCkInfo.ckTime" value-format="HH:mm:ss" :picker-options="{
                        selectableRange: '00:00:00 - 23:59:59'
                    }" placeholder="任意时间点">
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="auxilCkInfo.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeInfo('auxilCkInfo')">取 消</el-button>
                <el-button  class="btn" @click="sure('auxilCkInfo')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getAuxiliaryInfo } from '@/api/auxilInfo.js'
import { ckInventory } from "@/api/auxiliaryCrk.js"
export default {
    data() {
        return {
            auxCode: null,
            username: null,
            auxiliaryInfoList: [],
            auxilCkInfo: {
                auxiliaryInfoList: [],
                ckJbr: null,
                ckDate: null,
                ckTime: null,
                remark: null
            },
            rules: {
                ckJbr: [{ required: true, message: '请填写出库经办人' }],
                ckDate: [{ required: true, message: '请选择出库日期' }],
                ckTime: [{ required: true, message: '请选择出库时间' }],
            },
            loading: false,
            // focus: true,
            dialogFormVisible: false,
            formLabelWidth: '80px',
            keyDownTime: 0,
            keyUpTime: 0,
        }
    },
    mounted() {
        // this.focus = true
        this.onAuxCodeRef()
    },
    methods: {
        handleSearch() {
            this.loadAuxilaryInfo()
        },

        onAuxCodeRef(){
            this.$nextTick(()=>{
                this.$refs.auxCodeRef.focus()
            })
        },

        loadAuxilaryInfo() {
            if (this.auxCode) {
                this.loading = true
                getAuxiliaryInfo(this.auxCode).then(res => {
                    setTimeout(() => {
                        this.loading = false
                        if (res.code === '000000') {
                            if (this.auxiliaryInfoList.length > 0) {
                                if (this.auxiliaryInfoList.findIndex(item => item.auxCode === res.t.auxCode) === -1) {
                                    this.auxiliaryInfoList.push(res.t)
                                } else {
                                    this.$message.warning('该辅助工具已添加到出库清单中，请勿重复添加！')
                                }
                            } else {
                                this.auxiliaryInfoList.push(res.t)
                            }

                            var count = 1;
                            this.auxiliaryInfoList.forEach((item) => {
                                item.seq = count++
                                switch (item.status) {
                                    case '00':
                                        item.status_text = '已入库'
                                        break;
                                    case '01':
                                        item.status_text = '已出库'
                                        break;
                                    case '02':
                                        item.status_text = '使用未返回'
                                        break;
                                    case '03':
                                        item.status_text = '报废'
                                        break;
                                    default:
                                        item.status_text = ''
                                        break;
                                }
                            })
                        }
                        this.auxCode = null
                    }, 200)

                })
            }

        },
        handleReset() {
            this.auxCode = null
        },
        handleCk() {
            if (this.auxiliaryInfoList.length === 0) {
                this.$message.warning('请先添加要出库的辅助工具！')
            } else {
                // this.focus = false
                this.auxilCkInfo = {
                    auxiliaryInfoList: this.auxiliaryInfoList,
                    ckJbr: null,
                    ckDate: this.getDate(),
                    ckTime: this.getTime(),
                    remark: null
                }
                this.dialogFormVisible = true
            }
            // this.$message.success('出库成功！')
        },

        //提交出库单
        sure(auxilCkInfo) {
            this.$refs[auxilCkInfo].validate(valid => {
                if (valid) {
                    console.log(this.auxilCkInfo)
                    ckInventory(this.auxilCkInfo).then(res => {
                        if (res.code === '000000') {
                            this.$message.success('出库成功')
                        }

                        this.$refs[auxilCkInfo].resetFields()
                        this.dialogFormVisible = false
                        // this.focus = true
                        this.auxiliaryInfoList = []
                    })

                }
            })
        },

        //删除
        handleDel(auxCode) {
            this.$confirm('是否移除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let auxiIndex = this.auxiliaryInfoList.findIndex(item => item.auxCode === auxCode)
                if (auxiIndex !== -1) {
                    this.auxiliaryInfoList.splice(auxiIndex, 1)
                    this.$message.success('移除成功！')
                } else {
                    this.$message.error('移除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报废'
                })
            })
        },

        courierNumEnterDown() {
            this.keyDownTime = new Date().getTime()
        },
        courierNumEnterUp() {
            this.keyUpTime = new Date().getTime()
            if (this.keyUpTime - this.keyDownTime < 30) {
                console.log("扫码完成")
                this.loadAuxilaryInfo()
            } else {
                this.loadAuxilaryInfo()
            }
        },

        closeInfo() {
            this.$refs['auxilCkInfo'].resetFields();
            this.auxilCkInfo = {
                auxiliaryInfoList: [],
                ckJbr: null,
                ckDate: null,
                ckTime: null,
                remark: null
            }
            this.dialogFormVisible = false;
            // this.focus = true
        },
        getDate() {
            const nowDate = new Date();
            const date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate(),
            };
            const newmonth = date.month >= 10 ? date.month : "0" + date.month;
            const day = date.date >= 10 ? date.date : "0" + date.date;
            return date.year + "-" + newmonth + "-" + day;
        },
        getTime() {
            const nowDate2 = new Date();
            const time = {
                HH: nowDate2.getHours,
                mm: nowDate2.getMinutes
                // ss: nowDate2.getSeconds
            }
            const hours = time.HH >= 10 ? time.HH : '0' + time.HH
            const minute = time.mm >= 10 ? time.mm : '0' + time.mm
            // const second = time.ss >=10 ? time.ss :'0'+time.ss
            return hours + '-' + minute + '-00'
        }

    }

}


</script>

<style lang="scss" scoped>
.CkManage {

    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }


    .el-pagination,
    .el-form-item,
    .clearfix {
        text-align: left;
    }

    .ckSubmit {
        text-align: right;
    }

    .delColor {
        color: red;
    }

}
</style>